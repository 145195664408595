export default class Cookiebox {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      cookiebox: "cookies"
    };

    this.box = document.querySelector(`.${this.selectors.cookiebox}`);
    this.cookiesAccepted = localStorage.cookiesAccepted;
    this.btn = this.box.querySelector("button");

    if (!this.box || this.cookiesAccepted || !this.btn) return false;

    this.show();

    return true;
  }

  setupEvents() {
    this.btn.addEventListener("click", () => {
      localStorage.setItem("cookiesAccepted", true);
      this.hide();
    });
  }

  show() {
    this.box.style.display = "block";
  }

  hide() {
    this.box.style.display = "none";
  }
}
