import Cookiebox from "./Cookiebox";

$(document).ready(function () {
	
	initLanguages();
	if (document.querySelector('.page-home')) {
    initHomeSlider();
		initHomeTechnologySlider();
  }
	if (document.querySelector('.page-about-us')) {
		initStoryCarousel();
	}
	if (document.querySelector('.js-page-slider')) {
		initPageSlider();
	}
	if (document.querySelector('.page-products')) {
		initProductsHero();
	}
	if (document.querySelector('.page-product')) {
		if (window.innerWidth > 1024) {
			initProductHero();
		}
	}
	if (document.querySelector('.page-contact')) {
		if (window.innerWidth > 1024) {
			initContactHero();
		}
	}
	initMobileMenu();
	initScroll();
	new Cookiebox();
});

AOS.init({
  offset: 200,
  duration: 500,
  disable: 'mobile',
  once: true
});

function initLanguages() {
	$('.header .header__languages .languages__current').on('click',function() {
		$('.languages__dropdown').parent().stop(true,true).toggleClass('visible');
	});
}

function initHomeSlider() {
  $('.page-home .slider').cycle({
		timeout: 6000,
		speed: 500,
		slides: '> .slider__slide',
		pager: '> ul.slider__pager',
		pagerActiveClass: 'active',
		swipe: true
	});
}

function initHomeTechnologySlider() {
  $('.page-home .technology .technology__tabs').cycle({
		timeout: 0,
		speed: 800,
		slides: '> .tabs__slide',
		pager: '> ul.tabs__dots',
		pagerActiveClass: 'active',
		next: '> .tabs__slide .slide__inner .slide__copy .tabs__nav .nav__next',
		prev: '> .tabs__slide .slide__inner .slide__copy .tabs__nav .nav__prev',
		swipe: true
	});
}

function initStoryCarousel() {
	$('.story .story__carousel').owlCarousel({
		items: 4,
		margin: 0,
		responsive: {
			769: {
				items: 4
	    },
			561: {
				items: 3
	    },
			0: {
				items: 2
	    }
		}
	});
}

function initPageSlider() {
  $('.page-slider').cycle({
		timeout: 0,
		speed: 800,
		slides: '.page-slider__slide',
		pager: '.page-slider__slide ul.page-slider__dots',
		pagerTemplate: '<li></li>',
		pagerActiveClass: 'active',
		swipe: true
	});
}

function setProductsContentMargin() {
	const productsHero = document.getElementById('productsHero');
  const productsContent = document.getElementById('productsContent');
  const minusMargin = 30 * window.innerWidth / 1903;
  if (!productsContent || !productsHero) return false;
  productsContent.style.marginTop = `${productsHero.getBoundingClientRect().height - minusMargin}px`;
}

function initProductsHero() {
	if (window.innerWidth > 1024) {
		window.addEventListener('resize', () => setProductsContentMargin());
		setProductsContentMargin();
	  $('.page-products .hero .hero__title .title__switcher').on('click',function() {
	    $(this).stop(true,true).toggleClass('open');
	    $('.page-products .hero .hero__categories').stop(true,true).slideToggle({
	      step: () => setProductsContentMargin(),
	      complete: () => setProductsContentMargin()
	    });
	  });
	} else {
		$('.page-products .hero .hero__title .title__switcher').on('click',function() {
	    $(this).stop(true,true).toggleClass('open');
	    $('.page-products .hero .hero__categories').stop(true,true).slideToggle();
	  });
	}
}

function setProductContentMargin() {
	const productHero = document.getElementById('productHero');
	const productContent = document.getElementById('productContent');
  const minusMargin = 30 * window.innerWidth / 1903;
  if (!productContent || !productHero) return false;
  productContent.style.marginTop = `${productHero.getBoundingClientRect().height - minusMargin}px`;
}

function initProductHero() {
	window.addEventListener('resize', () => setProductContentMargin());
	setProductContentMargin();
}

function setContactMapMargin() {
	const contactHero = document.getElementById('contactHero');
	const contactMap = document.getElementById('contactMap');
  const minusMargin = 30 * window.innerWidth / 1903;
  if (!contactMap || !contactHero) return false;
  contactMap.style.marginTop = `${contactHero.getBoundingClientRect().height - minusMargin}px`;
}

function initContactHero() {
	window.addEventListener('resize', () => setContactMapMargin());
	setContactMapMargin();
}

function initMobileMenu() {
  $('.header__trigger').on('click',function() {
    $(this).stop(true,true).toggleClass('visible');
    $('.header__menu').stop(true,true).toggleClass('visible');
	});
}

function initScroll() {
	$('.scroll-init').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 800);
  });
}
