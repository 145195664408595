const initProgressBar = () => {
	window.onscroll = function () {
		progressBar()
	};
	
	function progressBar() {
		let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
		
		let scrolled = (winScroll / height) * 100;
		let progressBar = document.querySelector('#progressBar');
		
		progressBar.style.width = scrolled + "%";
	}
}
initProgressBar();

const resizeCoverInstagram = () => {
	const getWindowWidth = () => {
		return window.innerWidth
			|| document.documentElement.clientWidth
			|| document.body.clientWidth;
	}
	
	if (getWindowWidth() >= '768') {
		document.addEventListener("DOMContentLoaded", function () {
			const instaImage = document.querySelector('.insta-gallery-feed');
			const imgCover = document.querySelector('.instagram__cover > img');
			
			setTimeout(function(){
				['load', 'resize', 'DOMContentLoaded'].forEach(evt =>
					window.addEventListener(evt, reportWindowSize, false)
				);
			}, 3000);
			
			function reportWindowSize() {
				if (imgCover) {
					
					if (instaImage) {
						let imagesWidth = instaImage.width;
						let imagesHeight = instaImage.clientHeight;
						
						imgCover.style.width = imagesWidth;
						imgCover.style.height = imagesHeight;
					}
				}
			}
		});
	}
}

resizeCoverInstagram();
